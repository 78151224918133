import Auth from './auth';
import { setGlobal } from 'reactn'
let auth = new Auth();

class API {
  constructor() {
    this.baseURL = 'https://api2.castles.com.ng/api';
    this.header = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    this.token = localStorage.getItem("_token") ? localStorage.getItem('_token') : "";
  }

  login = async (body) => {
    return await fetch(`${this.baseURL}/login`, {
      headers: this.header,
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(async res => {
        if (!!res.access_token) {
          await auth._storeAuthCred(res);
          return true;
        }
        else {
          return false
        }
      })
      .catch(error => {
        console.log(error);
        return false;
      });
  }

  manageUser = async (id, endpoint) => {
    return await fetch(`${this.baseURL}/admin/users/${id}/${endpoint}`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
    })
      .then(res => res.json())
      .then(async res => {
        if (res.status == 1) {
          await this.getAgents();
          await this.getUsers();
          return true;
        }
        else {
          return false
        }
      })
      .catch(error => {
        console.log(error);
        // throw error;
        return false
      });
  }


  sendEmail = (body) => {
    fetch(`${this.baseURL}/send_email`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        return res
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  createUser = (body) => {
    fetch(`${this.baseURL}/admin/users/create`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res => res)
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  saveProperty = async (body) => {
    let url;
    await delete body.errors;
    await delete body.agent;
    await delete body.state;
    await delete body.locality;
    await delete body.tab
    console.log('body to be send', body);
    // if(body.id) {
      url = `${this.baseURL}/admin/properties/save`
    // }
    // else {
    //   url = `${this.baseURL}/admin/properties/save`
    // }
    return await fetch(url, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(async res => {
        if (res.status == 1) {
          await this.getProperties();
          return true
        }
        else {
          return false
        }
      })
      .catch(error => {
        console.log(error);
        // throw error;
        return false
      });
  }

  deleteProperty = async (id) => {
    return await fetch(`${this.baseURL}/admin/properties/destroy`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
      body: JSON.stringify({"id": [id]})
    })
      .then(res => res.json())
      .then(async res => {
        console.log('res', res)
        if (res.status == 1) {
          await this.getProperties();
          return true
        }
        else {
          return false
        }

      })
      .catch(error => {
        console.log(error);
        // throw error;
        return false
      });
  }

  approveDisapproveProperty = async (body, endpoint) => {
    return await fetch(`${this.baseURL}/admin/properties/${endpoint}`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(async res => {
        if (res.status == 1) {
          await this.getProperties();
          return true
        }
        else {
          return false
        }
      })
      .catch(error => {
        console.log(error);
        // throw error;
        return false
      });
  }

  getAgents = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/users?paginate=${quantity}&role=2`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { agents: res.data.data, totalAgents: res.data.total }
          }
          else {
            return { agents: [] }
          }

        })
        .catch(error => {
          console.log(error);
          throw error;
        })
    )
  }

  getSubscribers = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/subscribers?paginate=${quantity}`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { subscribers: res.data.data }
          }
          else {
            return { subscribers: [] }
          }
        })
        .catch(error => {
          throw error;
        })
    )
  }

  getProperties = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/properties?paginate=${quantity}`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            console.log(res.data)
            if(quantity < 10000) {
             this.getProperties(quantity + 1000);
            }
            return { properties: res.data.data, totalProperties: res.data.total}
          }
          else {
            return { properties: [] }
          }
        })
        .catch(error => {
          throw error;
        })
    )
  }

  getProperty = async (slug) => {
    return await fetch(`${this.baseURL}/admin/properties/${slug}`, {
      headers: { ...this.header, Authorization: `Bearer ${this.token}` },
      method: 'GET',
    })
      .then(res => res.json())
      .then(res => {
        if (!!res.data) {
          return res.data
        }
        else {
          return null;
        }
      })
      .catch(error => {
        throw error;
      })
  }

  getUsers = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/users?paginate=${quantity}&role=1`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { users: res.data.data, totalUsers: res.data.total}
          }
          else {
            return { users: [] }
          }

        })
        .catch(error => {
          throw error;
        })
    )
  }

  getRequests = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/properties/requests?paginate=${quantity}`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { requests: res.data.data, totalRequests: res.data.total }
          }
          else {
            return { requests: [] }
          }

        })
        .catch(error => {
          throw error;
        })
    )
  }

  getCountries = async () => {
    return await setGlobal(
      fetch(`${this.baseURL}/countries`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { countries: res.data }
          }
          else {
            return { countries: [] }
          }
        })
        .catch(error => {
          throw error;
        })
    )
  }

  getStates = async () => {
    return await setGlobal(
      fetch(`${this.baseURL}/states`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { states: res.data }
          }
          else {
            return { states: [] }
          }

        })
        .catch(error => {
          throw error;
        })
    )
  }

  getLocalities = async (id) => {
    return await setGlobal(
      fetch(`${this.baseURL}/localities/${id}`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          console.log(res)
          if (!!res.data) {
            return { localities: res.data }
          }
          else {
            return { localities: [] }
          }

        })
        .catch(error => {
          throw error;
        })
    )
  }

  getReports = async (quantity) => {
    if (!quantity) {
      quantity = 100
    }
    await setGlobal(
      fetch(`${this.baseURL}/admin/properties/reports?paginate=${quantity}`, {
        headers: { ...this.header, Authorization: `Bearer ${this.token}` },
        method: 'GET',
      })
        .then(res => res.json())
        .then(res => {
          if (!!res.data) {
            return { reports: res.data.data, totalReports: res.data.total }
          }
          else {
            return { reports: [] }
          }

        })
        .catch(error => {
          throw error;
        })
    )
  }
}

export default API;