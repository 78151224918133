import React, { Component } from 'react';


class NotFound extends Component {

    componentDidMount() {

    }

    render() {

        return ( 
          <div>
            <h1>NotFound</h1>
          </div>
        )

    }

}

export default NotFound;