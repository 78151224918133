import React from "react";

const SpinnerPage = () => {
  return (
    <>
      <span className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </span>
    </>
  );
}

export default SpinnerPage;